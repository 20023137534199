import { ClubType, ClubTypeFilterValue } from '@typings/operations';

import {
  ClubMeetingValues,
  ClubTypeMapping,
  RolesForSelectClubOfficers,
  RolesForSelectRotaractOfficers,
  RotaryRotaractRolesMapping,
} from './types';

export const CREATE_CLUB_MEETING_INITIAL_VALUES: ClubMeetingValues = {
  name: '',
  languageId: '',
  comment: '',
  weekday: undefined,
  frequency: '',
  time: '',
  type: undefined,
  locationVaries: false,
  locationNameOrDetail: '',
  lineOne: '',
  lineTwo: '',
  lineThree: '',
  countryId: '',
  city: '',
  stateId: '',
  postalCode: '',
  internationalProvince: '',
  detailForJoiningRemotely: '',
  onlineLocation: '',
};

// data to be removed after DIS call
export const ASSIGNED_CANDIDATES_TO_CLUB = [
  {
    id: 1,
    name: 'sharath1',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
  {
    id: 2,
    name: 'sharath2',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
  {
    id: 3,
    name: 'sharath3',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
  {
    id: 4,
    name: 'sharath4',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
  {
    id: 5,
    name: 'sharath5',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
  {
    id: 6,
    name: 'sharath6',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
  {
    id: 7,
    name: 'sharath7',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
  {
    id: 8,
    name: 'sharath8',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
  {
    id: 9,
    name: 'sharath9',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
  {
    id: 10,
    name: 'sharath10',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
  {
    id: 11,
    name: 'sharath11',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
  {
    id: 12,
    name: 'sharath12',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
  {
    id: 13,
    name: 'sharath13',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
  {
    id: 14,
    name: 'sharath14',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
  {
    id: 15,
    name: 'sharath15',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
  {
    id: 16,
    name: 'sharath16',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
  {
    id: 17,
    name: 'sharath17',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
  {
    id: 18,
    name: 'sharath18',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
  {
    id: 19,
    name: 'sharath19',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
  {
    id: 20,
    name: 'sharath20',
    email: 'abc@gmail.com',
    phone: '7075936000',
    role: 'prospective member',
    date: '12-1-2023',
    status: 'District reviewed inquiry',
    type: 'club',
  },
];

export const PREV_CES_YEARS = 5;

export const CLUB_EXECUTIVE_DIRECTOR_ASSISTANT =
  'Club Executive Director Assistant';

export const EMAIL_LABEL = 'EMAIL_LABEL';

export const EMAIL_PLACEHOLDER = 'EMAIL_PLACEHOLDER';

export const MEMBER_ID_TRANSLATION = 'MEMBER_ID_TRANSLATION';

export const ENTER_MEMBER_ID_TRANSLATION = 'ENTER_MEMBER_ID_TRANSLATION';

export const FIRST_NAME_LABEL = 'FIRST_NAME_LABEL';

export const FIRST_NAME_PLACEHOLDER = 'FIRST_NAME_PLACEHOLDER';

export const LAST_NAME_LABEL = 'LAST_NAME_LABEL';

export const LAST_NAME_PLACEHOLDER = 'LAST_NAME_PLACEHOLDER';

export const SEARCH_FOR_MEMBER_BTN = 'SEARCH_FOR_MEMBER_BTN';

export const ROTARACT_ADVISOR = 'Rotaract Advisor';

export const ROTARY_ROTARACT_ROLES_MAPPING: RotaryRotaractRolesMapping = {
  [RolesForSelectClubOfficers.ClubPresident]: [
    RolesForSelectRotaractOfficers.RotaractPresident,
    RolesForSelectClubOfficers.ClubPresident,
  ],
  [RolesForSelectClubOfficers.ClubServiceProjectsChair]: [
    RolesForSelectRotaractOfficers.RotaractServiceProjectsChair,
    RolesForSelectClubOfficers.ClubServiceProjectsChair,
  ],
  [RolesForSelectClubOfficers.ClubMembershipChair]: [
    RolesForSelectRotaractOfficers.RotaractMembershipChair,
    RolesForSelectClubOfficers.ClubMembershipChair,
  ],
  [RolesForSelectClubOfficers.ClubPublicImageChair]: [
    RolesForSelectRotaractOfficers.RotaractPublicImageChair,
    RolesForSelectClubOfficers.ClubPublicImageChair,
  ],
  [RolesForSelectClubOfficers.ClubTreasurer]: [
    RolesForSelectRotaractOfficers.RotaractTreasurer,
    RolesForSelectClubOfficers.ClubTreasurer,
  ],
  [RolesForSelectClubOfficers.ClubSecretary]: [
    RolesForSelectRotaractOfficers.RotaractSecretary,
    RolesForSelectClubOfficers.ClubSecretary,
  ],
  [RolesForSelectClubOfficers.ClubFoundationChair]: [
    RolesForSelectRotaractOfficers.RotaractFoundationChair,
    RolesForSelectClubOfficers.ClubFoundationChair,
  ],
};

export const CLUB_TYPE_MAPPING: ClubTypeMapping = {
  [ClubType.RotaractClub]: ClubTypeFilterValue.RotaractClub,
  [ClubType.RotaryClub]: ClubTypeFilterValue.RotaryClub,
  [ClubType.RotaractSatelliteClub]: ClubTypeFilterValue.RotaractSatelliteClub,
  [ClubType.SatelliteClub]: ClubTypeFilterValue.SatelliteClub,
};

export const ROTARY_HONORARY_TERMINATION_REASON_ID =
  '0fcd8d39-f9af-4d92-9706-67d82dd77f90';

export const ROTARACT_HONORARY_TERMINATION_REASON_ID =
  'ff627d26-f76e-4d99-9e45-c7f5e002cbf5';

export const CLUB_MEMBERSHIP_MANAGEMENT_STEPS = {
  EDIT_MEMBERSHIP: 1,
  CHANGE_MEMBERSHIP: 2,
};

export const ROTARY_FINANCE_MENU_NAME = 'menu-administration-finances';

export const ROTARACT_FINANCE_MENU_NAME = 'menu-rotaract-admin-finance';

export const HISTORY_PAST_YEARS = {
  TERMINATION: 5,
  DECEASED: 2,
};

export const ROTARACT_ADVISOR_ROLEID = 'a93b8f6f-3552-4e7c-9458-95fe56e137b1';
export const CES_ROLEID = '4bb6b2c2-e9e7-4def-b51e-98ffbc3ed38c';

export const CLUB_TAB_ROUTES = [
  'details',
  'roster',
  'manage-membership',
  'finances',
  'reports',
  'goals',
] as Array<string>;
