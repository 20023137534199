import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Member from '@domui-utils/workflow/member';

type replaceOfficerArgs = {
  leadershipId: string;
  individualId: string;
  date: string;
};

// Custom hook to handle API calls
export const useReplaceOfficer = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<DomuiError>(undefined);
  const [data, setData] = useState<DynamicData>();

  const replaceOfficer = async ({
    leadershipId: oldIndividualRolePk,
    individualId: newIndividualPk,
    date: effectiveDate,
  }: replaceOfficerArgs) => {
    const requestData = {
      oldIndividualRolePk,
      newIndividualPk,
      effectiveDate,
    };
    setLoading(true);
    setError(null);
    try {
      const wfService = new Member();
      const response = await wfService.ReplaceOfficer(requestData);
      setData(response);
      setLoading(false);
      return response;
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, replaceOfficer };
};
