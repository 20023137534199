import { buildAGsPageUrl, buildOfficersPageUrl } from './urlHelpers';

import { OperationType } from '@domain/districts';

export const PRECEDING_URL_LIST = (districtId: string) => [
  `/domui/district/${districtId}/members`,
  `/domui/district/${districtId}/members/officers`,
];

export const getBackUrl = (
  operationType: OperationType | null,
  districtId: string
) => {
  switch (operationType) {
    case OperationType.ASSIGN_TO_ROLE:
    case OperationType.UNASSIGN_ROLE_FROM_OFFICER:
    case OperationType.REPLACE_ROLE:
      return `/domui/district/${districtId}/members`;
    case OperationType.ASSIGN_TO_MEMBER:
    case OperationType.REASSIGN_OFFICER:
    case OperationType.REPLACE_OFFICER:
    case OperationType.REASSIGN_TO_ROLE:
    case OperationType.UNASSIGN_OFFICER_FROM_ROLE:
      return buildOfficersPageUrl(districtId);
    case OperationType.ASSIGN_TO_AG:
    case OperationType.REPLACE_AG:
      return buildAGsPageUrl(districtId);
    default:
      return `/domui/district/${districtId}`;
  }
};
