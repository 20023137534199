import { partition } from 'lodash';

// import { GetConferencesResults } from './type';

import { RecordList } from '@domui-domain/type';
import { isPastConference } from '@domui-use-cases/districts';

export const getSplitConferences = (conferences: RecordList) => {
  const [pastConferences, upcomingConferences] = partition(
    conferences,
    ({ endDate }) => isPastConference(endDate)
  );

  return { pastConferences, upcomingConferences };
};
