import React, { useEffect, useRef, useState } from 'react';

import { useParams } from '@reach/router';
import { useFormikContext } from 'formik';
import moment from 'moment';

import Select from '@components/Formik/Select';
import TextField from '@components/Formik/TextField';

import { genderSelectOptions } from './utils';

import { isEditLevel } from '@utils/access-helpers';

import { useAppConfig } from '@domui-hooks/appConfig';
import { useFetchClubPermission } from '@domui-hooks/iam/useFetchClubPermission';
import { useDominoUserAccount } from '@domui-hooks/useDominoUserAccount';

import { useTranslation } from '@external/react-i18next';

interface GenderProps {
  id: string;
}
export enum Gender {
  Dni = 'DNI',
  Female = 'Female',
  Male = 'Male',
  NotGiven = 'Not Given',
  Self = 'Self',
}

const GenderInputs: React.FC<GenderProps> = ({ id }) => {
  const { t } = useTranslation();
  const { user } = useAppConfig();
  const { individualId: profileIndividualID } = useParams();

  const individualIdForFetch = (user?.isLoggedIn && user?.individualId) || null;

  const { data: userData } = useDominoUserAccount(individualIdForFetch);
  const {
    data: iamClubData,
    // loading: iamClubLOADING,
    // error: IAMError,
    fetchClubPermission,
  } = useFetchClubPermission();

  const canAddMembers = isEditLevel(iamClubData?.memberships); // isEditLevel(iamClubData?.memberships);
  const requestorId = userData?.individualInfo?.id;
  const getRotaryYear = () => {
    return (moment().format('MM-DD') < '07-01'
      ? moment().year()
      : moment().year() + 1
    ).toString();
  };
  const payload = {
    data: [
      {
        target: `/clubs/${id}/membership`,
      },
      {
        target: `/clubs/${id}/leadership`,
        arguments: {
          targetTermYear: getRotaryYear(),
        },
      },
      {
        target: `/clubs/${id}`,
      },
    ],
  };
  useEffect(() => {
    fetchClubPermission(requestorId, payload);
  }, [requestorId]);

  // const isLeadershipRole =
  //   userData?.getIndividual.clubAffiliations.some(({ affiliations }) => {
  //     return affiliations.some(
  //       item => item.__typename === 'LeadershipAffiliation'
  //     );
  //   }) || false;
  let customSelectOptions = genderSelectOptions;
  if (canAddMembers) {
    const newOption = {
      label: t('global.gender.do-not-given', 'Not Given'),
      value: Gender.NotGiven,
    };
    const existValue = genderSelectOptions.some(
      item => item.value === Gender.NotGiven
    );

    const isSelfProfile = profileIndividualID === user?.individualId;

    if (existValue && isSelfProfile) {
      customSelectOptions = customSelectOptions.filter(
        item => item.value !== newOption.value
      );
    }

    if (!existValue && !isSelfProfile) {
      customSelectOptions.push(newOption);
    }
  }

  const { values, setFieldValue } = useFormikContext();
  const genderDescriptionInputRef = useRef<HTMLInputElement>(null);

  const [
    isGenderDescriptionInputVisible,
    setIsGenderDescriptionInputVisible,
  ] = useState(false);
  const [
    isGenderDescriptionInputFocused,
    setIsGenderDescriptionInputFocused,
  ] = useState(false);

  useEffect(() => {
    const currentGender = (values as { [key: string]: string }).gender;
    setIsGenderDescriptionInputVisible(currentGender === Gender.Self);
  }, [values]);

  useEffect(() => {
    if (isGenderDescriptionInputVisible && isGenderDescriptionInputFocused) {
      genderDescriptionInputRef?.current?.focus();
    }
  }, [isGenderDescriptionInputVisible, isGenderDescriptionInputFocused]);

  const handleGenderSelectChange = (e: string) => {
    if (e === Gender.Self) {
      setIsGenderDescriptionInputFocused(true);
      setFieldValue('genderDescription', '');
    } else {
      setIsGenderDescriptionInputFocused(false);
      setFieldValue('genderDescription', null);
    }
    setFieldValue('gender', e);
  };

  return (
    <>
      <Select
        name="gender"
        required
        label={t('edit-personal-details.form.gender-label', 'Gender')}
        description={t(
          'edit-personal-details.form.gender-info',
          'Gender is only visible to club officers.'
        )}
        options={customSelectOptions}
        onChange={handleGenderSelectChange}
        descriptionHighlighted
      />
      {isGenderDescriptionInputVisible && (
        <TextField
          name="genderDescription"
          inputRef={genderDescriptionInputRef}
          labelHidden
        />
      )}
    </>
  );
};

export default GenderInputs;
