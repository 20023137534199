import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';
import { size } from 'lodash';
import moment from 'moment';

import { Button } from '@components/Button';
import Calendar from '@components/Formik/Calendar';
import Loading from '@components/Loading';
import Note from '@components/Note';
import PageSteps from '@components/PageSteps';

import MembershipDetailSection from './MembershipDetailSection';
import IndividualInfo from '../IndividualInfo';

import { CLUB_MEMBERSHIP_MANAGEMENT_STEPS } from '@domain/clubs';

import { useErrorHandling } from '@use-cases/notifications';

import { SponsorSelect } from '@domui-components/Forms/Select/SponsorSelect';
import { useEditClubMembership } from '@domui-hooks/useEditClubMembership';
import { useMembershipActions } from '@domui-hooks/useMembershipActions';
import {
  getClubMembersPath,
  isMemberOrRotaractor,
} from '@domui-use-cases/clubs';
import { getMembershipTypeLabel } from '@domui-utils/getMembershipTypeLabel';
import { getFullName } from '@domui-presenters/web/pages/Clubs/utils';

import { useTranslation } from '@external/react-i18next';

import { MembershipType } from '@typings/operations';

type EditMembershipProps = {
  clubId: string;
  individualId: string;
} & RouteComponentProps;

const EditMembership: React.FC<EditMembershipProps> = ({
  clubId,
  individualId,
}) => {
  const { t } = useTranslation();

  const [sponsorId, setSponsorId] = useState<Array<string>>([]);

  const { editClubMembership } = useEditClubMembership();

  const {
    data,
    loading,
    error,
    fetchMembershipActions,
  } = useMembershipActions();

  useEffect(() => {
    if (clubId && individualId) {
      const orgId = clubId;
      fetchMembershipActions(orgId, individualId);
    }
  }, [clubId, individualId]);

  const [isSubmitButtonDisabled, setisSubmitButtonDisabled] = useState(true);

  useErrorHandling(error?.message, !!error);

  const DATE_FORMAT = 'DD MMM YYYY';

  if (loading || !data) {
    return <Loading />;
  }

  const isMemberOrRotaractorMembershipType = isMemberOrRotaractor(
    data?.member?.memberType
  );

  const isMemberType = data?.member?.memberType === MembershipType.Member;

  const canChangeAdmissionDate =
    false && moment().diff(moment(data?.member?.admissionDate), 'days') <= 30;

  const { club } = data || {};

  const {
    individualPk,
    prefix,
    firstName,
    middleName,
    lastName,
    suffix,
    photoUri,
    admissionDate,
    memberType: membershipType,
  } = data.member;

  const individual = {
    prefix,
    firstName,
    middleName,
    lastName,
    suffix,
  };

  const handleSubmit = async () => {
    let memberType: string = membershipType;
    if (memberType === 'Honorary') {
      memberType = 'Honorary Member';
    }
    await editClubMembership({
      individualId,
      clubId,
      admissionDate,
      memberType,
      sponsorIds: sponsorId,
    });
  };

  return (
    <div>
      <PageSteps
        className="p-0"
        backBtnClassName="inline-flex items-baseline font-bold text-bright-blue-600 text-xs leading-xs-heading"
        to={getClubMembersPath(clubId)}
        step={CLUB_MEMBERSHIP_MANAGEMENT_STEPS.EDIT_MEMBERSHIP}
        total={size(CLUB_MEMBERSHIP_MANAGEMENT_STEPS)}
        isArrowLarge
        navClassName="mt-6 desktop:mt-8 text-small font-bold"
      />

      <h2 className="mt-4 mb-7 font-light">
        {t('edit-membership.form.edit-membership-title', 'Edit Membership')}
      </h2>

      <IndividualInfo
        photoUri={photoUri}
        individualId={individualPk}
        individualName={getFullName(individual)}
        club={club}
      />

      <div className="tablet:w-1/2 desktop:w-2/5">
        <Formik
          initialValues={{
            admissionDate: moment(admissionDate).toDate(),
          }}
          onSubmit={handleSubmit}
        >
          <Form>
            {membershipType ? (
              <Button
                to={`/domui/club/${clubId}/change-membership/${individualId}`}
                text
                small
                className="mb-7 text-left"
                alignLeft
              >
                {isMemberOrRotaractorMembershipType
                  ? t(
                      'edit-membership.form.change-to-honorary',
                      'Change to honorary'
                    )
                  : t(
                      'edit-membership.form.change-to-active',
                      'Change to active'
                    )}
              </Button>
            ) : null}

            <MembershipDetailSection
              title={t(
                'edit-membership.form.membership-type',
                'Membership Type'
              )}
              description={getMembershipTypeLabel(t, membershipType)}
            />

            <div data-testid="edit-membership-form">
              {canChangeAdmissionDate && (
                <Calendar
                  name="admissionDate"
                  label={t(
                    'edit-membership.form.admission-date-label',
                    'Admission Date'
                  )}
                  required
                  disabled={moment().diff(moment(admissionDate), 'days') > 30}
                  minDate={moment()
                    .subtract(30, 'd')
                    .toDate()}
                  maxDate={moment()
                    .add(1, 'day')
                    .toDate()}
                />
              )}
              {!canChangeAdmissionDate && (
                <>
                  <MembershipDetailSection
                    title={t(
                      'edit-membership.form.admission-date-title',
                      'Admission Date'
                    )}
                    description={moment(admissionDate).format(DATE_FORMAT)}
                  />

                  {false && (
                    <Note>
                      <p>
                        {t(
                          'edit-membership.form.changes-to-admission-date-text',
                          'Changes to Admission Dates more than 30 days in the past are not allowed. Please contact <a href="{{contact}}">Data Services</a>.',
                          { contact: '#' }
                        )}
                      </p>
                    </Note>
                  )}
                </>
              )}
            </div>

            <div className="flex flex-col">
              {isMemberType && (
                <SponsorSelect
                  selectId="sponsorId"
                  selectName="sponsorId"
                  selectLabel={t(
                    'edit-membership.form.sponsored-by-label',
                    'Sponsored by'
                  )}
                  description={t(
                    'edit-membership.form.sponsors-maximum',
                    '2 sponsors maximum'
                  )}
                  handleChange={(individualId: string[]) =>
                    setSponsorId(individualId)
                  }
                  clubId={clubId}
                  currentIndividualId={individualId}
                  selectClassName="mb-11"
                  setisSubmitButtonDisabled={setisSubmitButtonDisabled}
                />
              )}
              {isMemberOrRotaractorMembershipType && (
                <>
                  <Button
                    className="max-w-lg w-full"
                    type="submit"
                    disabled={isSubmitButtonDisabled}
                  >
                    {t('change-membership.form.submit-label', 'Save Changes')}
                  </Button>
                  <Button
                    to={getClubMembersPath(clubId)}
                    text
                    className="max-w-lg w-full mt-6"
                  >
                    {t('edit-membership.form.cancel-button', 'Cancel')}
                  </Button>
                </>
              )}
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default EditMembership;
