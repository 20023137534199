import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Member from '@domui-utils/workflow/member';

type useAssignRoleToMemberArgs = {
  individualPk: string;
  rolePk: string;
  startDate: string;
  endDate: string;
  organizationPk: string;
};

// Custom hook to handle API calls
export const useAssignRoleToMember = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);
  const [data, setData] = useState<DynamicData>();

  const assignRoleToMember = async ({
    individualPk,
    rolePk,
    startDate,
    endDate,
    organizationPk,
  }: useAssignRoleToMemberArgs) => {
    const requestData = {
      individualPk,
      rolePk,
      startDate,
      endDate,
      organizationPk,
    };
    setLoading(true);
    setError(null);
    try {
      const wfService = new Member();
      const response = await wfService.AssignRoleToMember(requestData);
      setData(response);
      setLoading(false);
      return response;
    } catch (err) {
      setError(err as DomuiError);
      setLoading(false);
      return null;
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, assignRoleToMember };
};
