import React from 'react';

import { useParams } from '@reach/router';

import DesktopItem from './DesktopItem';
import MobileItem from './MobileItem';

import { getRotaryYear } from '@utils/datetime';

import { ConferenceItemProps } from '@domui-domain/districts';
import { isPastConference } from '@domui-use-cases/districts/conferences';

const DistrictConferenceItem: React.FC<ConferenceItemProps> = props => {
  const { districtId } = useParams();
  const RY = Number(getRotaryYear());

  const {
    conference: {
      relatedRotaryYear,
      endDate,
      districtId: conferenceDistrictId,
    },
  } = props;

  const isMyDistrict = conferenceDistrictId === districtId;

  const isPastConf = isPastConference(endDate);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function getYearLabel(date: any) {
    const [startYear] = date.split('-');
    const fullStartYear = parseInt(startYear, 10);
    return `${fullStartYear}`;
  }
  const valueRotaryYear = getYearLabel(relatedRotaryYear);
  const isThisYear = RY === Number(valueRotaryYear);
  const canEditConference = isThisYear && isMyDistrict && !isPastConf;

  const itemProps = {
    ...props,
    canEditConference,
    isMyDistrict,
  };

  return (
    <>
      <DesktopItem {...itemProps} />
      <MobileItem {...itemProps} />
    </>
  );
};

export default DistrictConferenceItem;
