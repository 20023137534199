/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';

import { RouteComponentProps, useLocation } from '@reach/router';
import { toString } from 'lodash';
import moment from 'moment';

import Wizard from '@components/Formik/Wizard';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';

import { hasChanges } from '../../MembershipManagement/AddMember/AddMember';
import ConfirmReplaceOfficer from '../ConfirmReplaceOfficer';
import ReplaceNonMemberToRole from '../ReplaceNonMemberToRole';
import { ReplaceNonMemberProps } from '../ReplaceNonMemberToRole/ReplaceNonMemberToRole';
import { confirmReplaceOfficerValidationSchema } from '../validationSchema';

import { useModal, useStopBrowserNavigate } from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

// import {
//   // useCreateIndividual,
//   useFetchClubRolesForAssignMember,
//   useFetchIndividualForAssignClubRole,
//   useManageClubLeadership,
//   useUpdateIndividual,
// } from '@repositories/clubs';

import { getYear, isFutureRY } from '@utils/datetime';
import { getClubRoleTValue } from '@utils/getClubRoleTValue';
import { localizedNavigate } from '@utils/localized-navigate';
import { getChannel } from '@utils/sendMessage';

import {
  ConfirmOfficerValues,
  Individual,
  SelectRoleValues,
} from '@domui-domain/clubs/types';
import SelectOfficer from '@domui-presenters/web/pages/Clubs/ManageClubOfficers/SelectOfficer';
import {
  getClubMembersPath,
  getOfficersPagePath,
  useRoleOptions,
} from '@domui-use-cases/clubs';
import {
  isCESNonMemberFlowEligible,
  isRotractAdvisorNonMemberFlowEligible,
} from '@domui-use-cases/clubs/helpers/getRoleEligibility';
import {
  mapDataToPayloadCreateIndv,
  mapPayloadToData,
} from 'src/domui/domain/clubs';
import { useCreateIndividual } from 'src/domui/hooks/useCreateIndividual';
import { useDominoUserAccount } from 'src/domui/hooks/useDominoUserAccount';
import { useFetchClubDisplayInfo } from 'src/domui/hooks/useFetchClubDisplayInfo';
import { useFetchCountries } from 'src/domui/hooks/useFetchCountries';
import { useFetchIndividual } from 'src/domui/hooks/useFetchIndividual';
import { useReplaceOfficer } from 'src/domui/hooks/useReplaceOfficer';
import { useUpdateIndividual } from 'src/domui/hooks/useUpdateIndividual';
import { getFullName } from '@domui-presenters/web/pages/Clubs/utils';

import { useTranslation } from '@external/react-i18next';

import { UpdateIndividualMutationVariables } from '@typings/operations';

interface ReplaceMemberToRoleProps extends RouteComponentProps {
  clubId: string;
  individualId: string;
  roleId: string;
  startDate: string;
  leadershipId: string;
  endDate: string;
  clubType: string;
}

type EmailProps = {
  id: string;
  nfKey: string;
  type: string;
  address: string;
  isPrimary: boolean;
};

type ReplaceRoleToMemberValues = Partial<
  SelectRoleValues & ReplaceNonMemberProps & ConfirmOfficerValues
>;

const ReplaceMemberToRole: React.FC<ReplaceMemberToRoleProps> = ({
  clubId,
  individualId,
  roleId,
  startDate,
  leadershipId,
  endDate,
  clubType,
}) => {
  const { t } = useTranslation();
  const [otherEmails, setOtherEmails] = useState<string[]>([]);
  const location = useLocation();
  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const [isBackClubMembersPage, setIsBackClubMembersPage] = useState<boolean>(
    false
  );

  const isFirstStep = (step: number) => step === 0;

  const modalBackHandler = () => {
    show(true);
  };

  const { globalHide, setGlobal } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  const backUrlFromState = (location?.state as Record<string, string>)?.backUrl;

  const [isClubMembersPage] = useState(Boolean(backUrlFromState?.length));

  const stepBack = (step: number, setStep: (step: number) => void) => {
    if (isFirstStep(step)) {
      isClubMembersPage
        ? localizedNavigate(getClubMembersPath(clubId))
        : localizedNavigate(getOfficersPagePath(clubId));
    } else {
      setStep(step - 1);
    }
  };

  const {
    data: replaceData,
    loading: replaceOfficerLoading,
    error: replaceOfficerError,
    replaceOfficer,
  } = useReplaceOfficer();

  // const [
  //   updateIndividual,
  //   {
  //     error: updateIndividualError,
  //     called: updateIndividualCalled,
  //     loading: updateIndividualLoading,
  //   },
  // ] = useUpdateIndividual();
  const {
    data: fetchRiIndividualIdData,
    loading: updateLoading,
    error: updateError,
    updateIndividual,
  } = useUpdateIndividual();

  // const [
  //   createIndividual,
  //   {
  //     error: createIndividualError,
  //     called: createIndividualCalled,
  //     loading: createIndividualLoading,
  //   },
  // ] = useCreateIndividual();
  const {
    data: createIndividualData,
    loading: createIndividualLoading,
    error: createIndividualError,
    createIndividual,
  } = useCreateIndividual();

  const { addSuccess, addError } = useNotifications();

  const {
    data: countriesRegions,
    //  loading
    error: errorCountries,
    fetchCountries,
  } = useFetchCountries();

  useEffect(() => {
    fetchCountries();
  }, []);

  // const { data, loading, error } = useFetchIndividualForAssignClubRole(
  //   clubId,
  //   individualId
  // );

  const { data, loading, error, fetchIndividual } = useFetchIndividual();
  const { data: clubData, fetchClubDisplayInfo } = useFetchClubDisplayInfo();

  useEffect(() => {
    if (clubId) {
      fetchClubDisplayInfo(clubId);
    }
  }, [clubId]);

  useEffect(() => {
    fetchIndividual(individualId || '');
  }, [individualId]);
  // const data = {};
  const isFutureRotaryYear = isFutureRY(getYear(endDate));

  // const {
  //   data: dataClubRoles,
  //   loading: loadingClubRoles,
  //   error: errorClubRoles,
  // } = useFetchClubRolesForAssignMember(clubId, roleId, clubType);
  const { options: roleOptions } = useRoleOptions(clubId, false);

  // const {
  //   replaceClubLeadership,
  //   isLoading: isLoadingClubLeadership,
  //   isError: isErrorClubLeadership,
  // } = useManageClubLeadership({ clubId });

  const isError = Boolean(replaceOfficerError || error);

  const isLoading =
    replaceOfficerLoading || updateLoading || createIndividualLoading;

  const errorMessage = t('replace-club-officer.error', 'An error occurred.');

  useErrorHandling(errorMessage, isError, 'replace-club-officer.error');

  const leavePage = (step: number, setStep: (step: number) => void) => {
    globalHide();

    if (isBackClubMembersPage) {
      localizedNavigate(getClubMembersPath(clubId));
    }
    stepBack(step, setStep);
  };

  const handleBackArrowClick = (
    step: number,
    setStep: (step: number) => void
  ) => {
    stepBack(step, setStep);
    window.scrollTo(0, 0);
  };

  if (isLoading) {
    return <Loading />;
  }

  const primaryEmail = (emails: EmailProps[]) => {
    return emails?.filter(email => email.isPrimary === true);
  };

  const individual = {
    id: toString(data?.id),
    name: toString(
      getFullName(
        data as {
          prefix: string;
          firstName: string;
          middleName: string;
          lastName: string;
          suffix: string;
        }
      )
    ),
    email: toString(primaryEmail(data?.emails)?.[0]?.address),
    membershipAdmissionDate: null,
    membershipTerminationDate: null,
  };
  const role = roleOptions?.find(
    ({ id: roleItemId }: { id: string }) => roleItemId === roleId
  );
  // const clubAffiliations =
  //   data?.individual.clubAffiliations?.[0]?.affiliations || [];

  // const membershipAffiliation = clubAffiliations.find(
  //   affiliation => affiliation.__typename === 'MembershipAffiliation'
  // );

  // if (
  //   individual &&
  //   membershipAffiliation?.__typename === 'MembershipAffiliation'
  // ) {
  //   individual.membershipAdmissionDate = membershipAffiliation.admissionDate;
  //   individual.membershipTerminationDate =
  //     membershipAffiliation.terminationDate;
  // }
  const isReplaceNonMemberFlowEligible =
    role &&
    (isRotractAdvisorNonMemberFlowEligible(role.id) ||
      isCESNonMemberFlowEligible(role.id));

  const handleSubmit = async (
    handleSubmitResponse: ReplaceRoleToMemberValues
  ) => {
    const {
      individualToReplace,
      role,
      individual,
      effectiveDate,
      email,
      initialIndividualValues,
      individualData,
    } = handleSubmitResponse;

    let validEffectiveDate;

    if (effectiveDate) {
      const momentDate = moment(effectiveDate);
      if (momentDate.isValid()) {
        validEffectiveDate = momentDate.toDate(); // Convert to JavaScript Date object if valid
      } else {
        validEffectiveDate = new Date(); // Default to current date if invalid
      }
    } else {
      validEffectiveDate = new Date(); // Default to current date if null or undefined
    }

    const date = validEffectiveDate.toISOString().split('T')[0];
    let replaceResponse;

    // If replace non member flow eligible role or Rotract advisor flow
    if (isReplaceNonMemberFlowEligible) {
      let isUpdateIndividualSuccess = false;
      let isCreateIndividualSuccess = false;
      let updateIndividualOrNot = false;
      // If existing individual selected 1. update individual 2. replace individual
      // If new user added - 1. create individual 2. Replace individual
      if (individualToReplace?.id) {
        // update individual
        updateIndividualOrNot = hasChanges(
          individualToReplace,
          initialIndividualValues
        );
        if (updateIndividualOrNot) {
          const payload = mapPayloadToData(
            individualToReplace,
            individualToReplace?.id,
            individualData,
            countriesRegions
          );

          const indvId = individualToReplace?.id;
          const updateIndividualData = await updateIndividual(indvId, payload);
          if (updateIndividualData?.id) {
            isUpdateIndividualSuccess = true;
          }
        }

        // show error message when update individual fails
        if (updateError) {
          addError(updateError?.message || '');
        }

        // if (result?.data?.updateIndividual?.individualId) {
        // }
      } else {
        // create individual
        const payload = mapDataToPayloadCreateIndv(
          individualToReplace,
          individual,
          countriesRegions
        );
        const individualData = await createIndividual(payload);
        // const result = await createIndividual({
        //   variables: individualToReplace,
        // });

        if (createIndividualError) {
          addError(createIndividualError?.message || '');
        }

        const individualId = String(individualData?.id);
        if (individualToReplace) {
          individualToReplace.id = individualId;
        }
        if (individualId) {
          isCreateIndividualSuccess = true;
        }
      }

      // replace membership
      if (
        isUpdateIndividualSuccess ||
        isCreateIndividualSuccess ||
        !updateIndividualOrNot
      ) {
        //

        const params = {
          // roleId: toString(role?.id),
          leadershipId,
          individualId: toString(individualToReplace?.id),
          date,
        };

        replaceResponse = await replaceOfficer(params);
      }
    } else {
      const params = {
        leadershipId,
        individualId: toString(individualToReplace?.id),
        date,
        // newEmail: email !== individualToReplace?.email ? email : null,
      };

      replaceResponse = await replaceOfficer(params);
    }

    if (replaceResponse) {
      addSuccess(
        t(
          'club-replace-officer.assign-role.success-message',
          'Success! {{individualName}} has been replaced by {{replacedName}} with the role of {{roleName}}.',
          {
            individualName: individual?.name,
            replacedName:
              individualToReplace?.name || individualToReplace?.localizedName,
            roleName: getClubRoleTValue(t, role?.name || ''),
          }
        ),
        { id: 'form.club-replace-officer.success' }
      );
      getChannel('manage-club-officers').postMessage(
        t(
          'club-officer-tab-update.message',
          'Please refresh this browser tab to see updated information.'
        )
      );
      setGlobal(false);
      localizedNavigate(backUrlFromState || getOfficersPagePath(clubId));
    }
  };

  ConfirmReplaceOfficer.schema = confirmReplaceOfficerValidationSchema(
    t,
    isFutureRotaryYear,
    otherEmails
  );

  const handleCancelBtnClick = (e?: React.MouseEvent) => {
    e?.preventDefault();
    show(true);
    setIsBackClubMembersPage(true);
  };

  const checkPopup = (step: number, setStep: (step: number) => void) => {
    if (isShowing && isFirstStep(step)) {
      leavePage(step, setStep);
    }
  };

  const getConfirmationModal = (
    step: number,
    setStep: (step: number) => void
  ) => {
    return (
      <LeaveFormConfirmationModal
        isOpen={isShowing}
        closeModal={() => {
          globalHide();
          setIsBackClubMembersPage(false);
        }}
        onConfirm={() => {
          leavePage(step, setStep);
        }}
      />
    );
  };

  const pages = isReplaceNonMemberFlowEligible
    ? [SelectOfficer, ReplaceNonMemberToRole, ConfirmReplaceOfficer]
    : [SelectOfficer, ConfirmReplaceOfficer];
  const clubValues = {
    id: clubData?.DominoId,
    name: clubData?.Name,
    clubType: clubData?.Type,
  };

  if (clubValues?.id && individual?.name) {
    return (
      <Wizard
        pages={pages}
        initialValues={{
          club: clubValues?.id
            ? clubValues
            : { id: '', name: '', clubType: '' },
          role: {
            name: toString(role?.value),
            id: toString(role?.id),
          },
          slot: {
            start: moment(startDate),
            end: moment(endDate),
          },
          clubId: clubData?.DominoId,
          clubName: clubData?.Name,
          isReplaceNonMemberFlowEligible,
          effectiveDate: null,
          isMemberRow: true,
          individual,
          email: '',
          individualPk: '',
          rolePk: '',
          startDate: '',
          endDate: '',
          organizationPk: '',
          customStart: '',
          customEnd: '',
          initialIndividualValues: {},
        }}
        onSubmit={handleSubmit}
        setOtherEmails={emails => setOtherEmails(emails)}
        handleBackArrowClick={handleBackArrowClick}
        handleCancelBtnClick={handleCancelBtnClick}
        confirmationModal={getConfirmationModal}
        checkPopup={checkPopup}
      />
    );
  }
  return null;
};

export default ReplaceMemberToRole;
