import * as React from 'react';
import { useState } from 'react';

import Collapse from '@kunukn/react-collapse';
import classNames from 'classnames';
// import moment from 'moment';

import { Avatar } from '@components/Avatar';
// import ClubsList from '@components/ClubsList';
import { ContextMenu } from '@components/ContextMenu';
import Icon from '@components/Icon';
import Link from '@components/Link';

import { mapRoleLabelForRotaractors } from '../../utils';
// import RoleAndTypeLabel from '../RoleAndTypeLabel';

import { getClubDestinationURL } from '@utils/getClubDestination';
import { storePreviousPagePath } from '@utils/storePreviousPagePath';

import { getClubMembersPath } from '@domui-use-cases/clubs/helpers';

import { useTranslation } from '@external/react-i18next';

// import { RosterResultRowFragment } from '@typings/operations';

// export interface IRosterResultMobile
//   extends RosterResultRowFragment,
//     RosterResultProps {}

// const TERMINATION_DATE_FORMAT = 'DD MMM YYYY';
// const CURRENT_DATE_FORMAT = 'YYYY-MM-DD';
type RosterResultRowFragment = {
  clubId: string;
  nfId: string;
  profile: {
    firstName: string;
    id: string;
    lastName: string;
    middleName: string;
    photoUri: string;
    localizedName: string;
    name: string;
  };
  email: {
    email: string;
    isPrimary: boolean;
    type: string;
  };
  phone: {
    isFax: boolean;
    isPrimary: boolean;
    phone: string;
    type: string;
  };
  roles: {
    endDate: string;
    role: string;
    startDate: string;
  };
  contextMenuItems: {
    id: number;
    label: string;
    path: string;
  }[];
};

const RosterResultMobile: React.FC<RosterResultRowFragment> = ({
  clubId,
  profile,
  email,
  phone,
  roles,
  nfId,
  // id,
  // clubType,
  // clubId,
  // name,
  // email,
  // localizedName,
  // photoUri,
  // phoneNumber,
  // riIndividualId,
  contextMenuItems,
  // activeAssociatedClubs,
  // type,
  // isDeceasedFiltered,
  // isTerminatedFiltered,
  // isLeadershipRole,
  // dates,
  // terminationHistory,
  // hasActiveMembershipInOtherClubs,
  // isDeceasedOutsideOfClub,
  // isTerminatedAndHasActiveMembershipInOtherClubs,
  // activeMembership,
  // clubLeadershipInfo,
  // admissionDate,
  // isDeceased,
  // isNonMember,
  // activeLeaders,
  // membershipsFromThisClub,
}) => {
  const { t } = useTranslation();

  const isOpened = false;

  // const isMemberIdShown = isLeadershipRole && activeMembership && !isDeceased;

  const [isExpanded, setIsExpanded] = useState(isOpened);

  const isNameHyperlinked = true;
  //   (hasActiveMembershipInOtherClubs ||
  //     isTerminatedAndHasActiveMembershipInOtherClubs ||
  //     isNonMember) &&
  //   !isDeceased;

  const clubRosterPath = getClubDestinationURL(clubId, 'roster');

  const primaryEmail: string = email?.email ? email?.email : '';
  const primaryPhone: string = phone?.phone ? phone?.phone : '';
  const name: string = `${profile?.firstName ? profile?.firstName : ''} ${
    profile?.middleName ? profile?.middleName : ''
  } ${profile?.lastName ? profile?.lastName : ''}`;

  return (
    <div
      data-label="RosterResult"
      className={classNames(`member-row-mobile desktop:hidden w-full px-5`, {
        'bg-bright-blue-100 pb-8 -my-4 pt-5': isExpanded,
        'member-row-mobile--expanded': isExpanded,
      })}
    >
      <div
        role="button"
        tabIndex={0}
        onClick={() => setIsExpanded(!isExpanded)}
        className="relative w-full member-row-info flex items-center text-left text-xs font-normal leading-6 focus:shadow-none tap-highlight-color-transparent overflow-hidden"
        data-testid="toggle-expand-btn"
      >
        <Avatar
          photoUri={profile?.photoUri || undefined}
          className="flex justify-center self-center member-row-avatar desktop:w-20"
        />
        <div className="w-4/6">
          <div
            className={classNames('mr-auto self-center flex-auto', {
              'w-full': !primaryEmail,
            })}
          >
            <div className="flex-1 text-xs leading-6">
              <h3
                className={classNames(
                  'font-bold text-md mb-0 desktop:absolute desktop:-mt-4',
                  {
                    underline: isNameHyperlinked,
                  }
                )}
              >
                {isNameHyperlinked ? (
                  <Link
                    noLinkStyles
                    to={`/domui/profile/${profile?.id}`}
                    onClick={() => storePreviousPagePath(clubRosterPath)}
                  >
                    {profile?.name}
                  </Link>
                ) : (
                  `${profile?.name}`
                )}
              </h3>
              <p className="text-xs text-gray-400 mb-0 mt-2">
                {profile?.localizedName}
              </p>
              {/* {isMemberIdShown && ( */}
              <p className="text-xs mb-0">
                {t(
                  'metadata.label.members.memberid',
                  'ID: {{ individualId }}',
                  {
                    individualId: nfId,
                  }
                )}
              </p>
              {/* )} */}
              {/* {isTerminatedAndHasActiveMembershipInOtherClubs && (
                <ClubsList clubs={activeAssociatedClubs} />
              )} */}
            </div>
          </div>
        </div>
        <div
          aria-hidden="true"
          className={classNames('absolute right-0 absolute-vertical-center', {
            hidden: isExpanded,
          })}
        >
          <Icon name="arrows/down" size="17" color="black" />
        </div>
        <div
          aria-hidden="true"
          className={classNames('absolute right-0 top-1', {
            hidden: !isExpanded,
          })}
        >
          <Icon name="ui/close" size="17" color="black" />
        </div>
      </div>

      <Collapse isOpen={isExpanded} className="collapse-css-transition">
        <div className="w-full overflow-hidden">
          {/* {((!isTerminatedFiltered && !isDeceasedFiltered) ||
            !terminationHistory) && (
            <div className="pl-16 pr-8 text-xs leading-6 mt-6">
              {clubLeadershipInfo.length ? (
                clubLeadershipInfo.map((role, index) => (
                  <RoleAndTypeLabel
                    key={`${index + role.roleName}`}
                    type={type}
                    role={role}
                    dates={dates}
                    wrapperClasses="mb-2"
                  />
                ))
              ) : (
                <RoleAndTypeLabel type={type} dates={dates} />
              )}
              {membershipsFromThisClub.map((clubMember, index) => {
                if (
                  clubMember.clubId === clubId &&
                  clubMember.terminationDate
                ) {
                  const currentDate = moment(
                    moment().format(CURRENT_DATE_FORMAT)
                  );
                  const terminationDate = moment(clubMember.terminationDate);

                  if (terminationDate.isSameOrAfter(currentDate)) {
                    return (
                      <span
                        key={`${index + clubId}`}
                        className="text-xs text-gray-400"
                      >
                        {t(
                          'roster-result.member.termination-future-termination',
                          'Terminated as of {{ date }}',
                          {
                            date: terminationDate.format(
                              TERMINATION_DATE_FORMAT
                            ),
                          }
                        )}
                      </span>
                    );
                  }
                }
                return null;
              })}
            </div>
          )} */}

          {/* {terminationHistory && !clubLeadershipInfo.length && ( */}
          <div className="pl-16 pr-8 text-xs leading-6 mt-6">
            {/* {isDeceasedOutsideOfClub && (
                <p className="text-xs mb-0 pr-5">
                  -{' '}
                  {t(
                    'roster-result.member.termination-reason-deceased',
                    'Deceased'
                  )}
                </p>
              )} */}
            {roles && (
              <p className="text-xs mb-0 pr-5">
                {t(
                  'roster-result.member.termination-history-active-membership',
                  '{{ type }} {{ date }}-present',
                  {
                    type: mapRoleLabelForRotaractors(t, roles?.role),
                    date: new Date(roles.startDate).getFullYear(),
                  }
                )}
              </p>
            )}
            {/* {terminationHistory.map(item => (
                <>
                  <p className="text-xs mb-0 pr-5 mt-3">
                    {item.terminationDates}
                  </p>
                  <p className="text-xs mb-0 pr-5 ml-2">
                    {item.terminationReason}
                  </p>
                </>
              ))} */}
          </div>
          {/* )} */}
          <div className="pl-16 pr-8 text-xs leading-6 mt-6">
            {primaryEmail ? (
              <p className="text-xs mb-0 truncate">
                <a href={`mailto:${primaryEmail}`}>{primaryEmail}</a>
              </p>
            ) : (
              <p className="text-xs mb-0" />
            )}
            {primaryPhone ? (
              <p className="text-xs mb-0 text-gray-600 truncate">
                <a href={`tel:${primaryPhone}`}>{primaryPhone}</a>
              </p>
            ) : (
              <p className="text-xs mb-0" />
            )}
          </div>
          {contextMenuItems.length > 0 && (
            <ContextMenu
              title={name}
              isRightIndent={false}
              items={contextMenuItems}
              optionsToggleButton={Boolean(true)}
              passThrough={{
                // clubType,
                individualId: profile?.id,
                memberName: name,
                // membershipType: type,
                // admissionDate,
                backUrl: getClubMembersPath(clubId),
                // activeLeaders,
              }}
            />
          )}
        </div>
      </Collapse>
    </div>
  );
};

export default RosterResultMobile;
