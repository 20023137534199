import Workflow from './base';

class Conference extends Workflow {
  constructor() {
    super('conferencewf');
  }

  async getAllConferencesByDistrictPrimary(districtId: string) {
    this.setAction('allConferencesByDistrictPrimary');
    this.setData({
      orgId: districtId,
    });
    try {
      const res = await this.getCall();
      return res?.data?.wfRes || res?.data || undefined;
    } catch (error) {
      throw new Error('Failed to fetch conferences by district info.');
    }
  }
}

export default Conference;
