export type Maybe<T> = T | null;

export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  // Void: any;
};
export enum MeetingType {
  Hybrid = 'Hybrid',
  Online = 'Online',
  Physical = 'Physical',
}

export type PresidentialRepresentative = {
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  middleName?: Maybe<Scalars['String']>;
};

export type ParticipatingDistricts = {
  id: Scalars['String'];
  riDistrictId: Scalars['Int'];
};

// export type ConferenceFeedbackResult = {
//   id: Scalars['String'];
//   type: Scalars['String'];
// };

export type ConferenceAddress = {
  city?: string | null | undefined;
  country?: string | null | undefined;
  internationalProvince?: string | null | undefined;
  lineOne?: string | null | undefined;
  lineThree?: string | null | undefined;
  lineTwo?: string | null | undefined;
  postalCode?: string | null | undefined;
  state?: string | null | undefined;
};

export type GetConferencesResults = {
  id?: Maybe<Scalars['String']>;
  districtId?: Maybe<Scalars['String']>;
  nfKey?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['String']>;
  relatedRotaryYear?: string | null | undefined;
  venueName?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  prAccommodation?: Maybe<Scalars['String']>;
  locationCityState?: Maybe<Scalars['String']>;
  locationCountry?: Maybe<Scalars['String']>;
  locationNameEng?: Maybe<Scalars['String']>;
  locationCityStateEng?: Maybe<Scalars['String']>;
  presRepresentativeHotel?: Maybe<Scalars['String']>;
  presRepresentativeHotelAddress?: Maybe<Scalars['String']>;
  presRespresntativeHotelPhone?: Maybe<Scalars['String']>;
  prComment?: Maybe<Scalars['String']>;
  govComment?: Maybe<Scalars['String']>;
  venueType?: Maybe<MeetingType> | undefined;
  conferenceAddress?: Maybe<ConferenceAddress>;
  onlineLocation?: Maybe<Scalars['String']>;
  locationNameOrDetail?: Maybe<Scalars['String']>;
  detailForJoiningRemotely?: Maybe<Scalars['String']>;
  prAddress?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
  isPresRepRequested?: Maybe<Scalars['Boolean']>;
  presRepAccPhone?: Maybe<Scalars['String']>;
  createdByRiDistrictId?: Maybe<Scalars['String']>;
  //   feedbacks: Array<ConferenceFeedbackResult>;
  //   isDistrictPresRep?: Maybe<Scalars['Boolean']>;
  participatingDistricts?: Maybe<Scalars['String']>; // Maybe<Array<ParticipatingDistricts>>;
  presidentialRepresentative?: Maybe<Scalars['String']>; // Maybe<PresidentialRepresentative>;
};
