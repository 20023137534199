import { Gender } from '@domui-components/Formik/GenderInputs/index';

import { AddressType } from '@typings/operations';

type Language = {
  id: string;
  languageId: string;
  languageName: string;
  isPrimary: boolean;
  isPreferred: boolean;
  nfkey: string;
};

type Phone = {
  id: string;
  type: string;
  number: string;
  countryId: string;
  countryName: string;
  extension: null;
  isPrimary: Boolean;
  isFax: boolean;
  nfKey: string;
};
type Address = {
  id: string;
  nfKey: string;
  type: string;
  lineOne: string;
  lineTwo: string;
  lineThree: string;
  countryId: string;
  countryName: string;
  city: string;
  stateId: string;
  stateName: string;
  internationalProvince: string;
  postalCode: string;
  isPrimary: boolean;
};
type Email = {
  id: string;
  nfKey: string;
  type: string;
  address: string;
  isPrimary: boolean;
};

export default (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  individual?: any,
  filtersFirstName?: string,
  filtersLastName?: string,
  filtersEmail?: string
  // isRotaractClub?: boolean
) => {
  // const { individual: selectedIndividual, membershipList } = individual || {};
  const primaryLanguage = individual?.languages?.filter(
    (language: Language) => language.isPrimary === true
  );
  const primaryPhone = individual?.phones?.filter(
    (phone: Phone) => phone.isPrimary === true
  );
  const primaryAddress = individual?.addresses?.filter(
    (address: Address) => address.isPrimary === true
  );
  const primaryEmail = individual?.emails?.filter(
    (email: Email) => email.isPrimary
  );

  const constructFullName = (person: {
    prefix: string;
    firstName: string;
    middleName: string;
    lastName: string;
    suffix: string;
  }) => {
    // Only include non-null values in the full name
    const { prefix, firstName, middleName, lastName, suffix } = person;
    return [prefix, firstName, middleName, lastName, suffix]
      .filter(namePart => namePart) // Remove null or undefined parts
      .join(' '); // Join remaining parts with a space
  };
  return {
    name: constructFullName(individual) || '',
    firstName: individual?.firstName || filtersFirstName || '',
    lastName: individual?.lastName || filtersLastName || '',
    middleName: individual?.middleName || '',
    localizedName:
      individual?.localizedName || constructFullName(individual) || '',
    email: primaryEmail?.[0]?.address || filtersEmail || '',
    prefix: individual?.prefix || '',
    suffix: individual?.suffix || '',
    primaryLanguage: primaryLanguage?.[0]?.languageId || '',
    gender: individual?.gender || Gender.NotGiven,
    yearOfBirth: individual?.yearOfBirth ? String(individual?.yearOfBirth) : '',
    dateOfBirth: individual?.dateOfBirth || null,
    primaryPhone: {
      number: primaryPhone?.[0]?.number || '',
      countryId: primaryPhone?.[0]?.countryId || '',
      country: primaryPhone?.[0]?.countryName || '',
    },
    primaryAddress: {
      lineOne: primaryAddress?.[0]?.lineOne || '',
      lineTwo: primaryAddress?.[0]?.lineTwo || '',
      lineThree: primaryAddress?.[0]?.lineThree || '',
      countryId: primaryAddress?.[0]?.countryId || '',
      city: primaryAddress?.[0]?.city || '',
      type: primaryAddress?.[0]?.type || AddressType.Home,
      postalCode: primaryAddress?.[0]?.postalCode || '',
      state: primaryAddress?.[0]?.stateName || '',
      stateId: primaryAddress?.[0]?.stateId || '',
      hasStates: false,
    },
    // hasActiveMembership: membershipList
    //   ? getActiveMembershipsByClubType(membershipList, isRotaractClub).length >
    //     0
    //   : false,
    // recentMemberships:
    //   membershipList?.filter(membersip =>
    //     moment(membersip.terminationDate).isSameOrAfter(
    //       moment().subtract(30, 'd')
    //     )
    //   ) || [],
  };
};
