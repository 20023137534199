/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from 'react';

const TestApp: React.FC = () => {
  useEffect(() => {
    // Load the first script
    const script = document.createElement('script');
    script.src = 'https://philuat.rotary.org/api/loadData.js'; // Path to your JS file
    script.async = true;

    script.onload = () => {
      const baseURL = 'https://philuat.rotary.org'; // Update with your actual build path
      const initialData = {
        invoice: {
          user: {
            authenticated: true,
            member_id: '10068139',
            first_name: 'John',
            last_name: 'Cumbow',
            localized_name: '',
            preferred_language: 'en',
            phone: '',
            email: 'RIMailTest+Cumbow-10068139@gmail.com',
            address: {
              line1: '50 North Laura St',
              line2: 'Ste 2000',
              line3: '',
              city: 'Jacksonville',
              state: 'FL',
              zip: '32202',
              intl_province: '',
              country: 'United States',
            },
            individual: {},
            organization: {
              id: '61972',
              type: 'Rotary Club',
            },
            created_by: 'RIMailTest+Cumbow-10068139@gmail.com',
          },
        },
        mode: 'invoice',
      };

      // Assuming loadReactApp is defined globally by loadData.js
      if (typeof (window as any).loadReactApp === 'function') {
        (window as any).loadReactApp(
          baseURL,
          initialData,
          document.getElementById('app')
        );
      }
      // console.log('Script loaded successfully.');
    };

    // Append scripts to the body
    document.head.appendChild(script);

    // Cleanup on component unmount
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return <div id="app" />;
};

export default TestApp;
