import React, { useState } from 'react';

import classNames from 'classnames';
// import { head } from 'lodash';

import { Button } from '@components/Button';
import { ResultsList, ResultsListItem } from '@components/ResultsList';

import DeleteConferenceModal from './DeleteConferenceModal';
import DistrictConferenceHeader from '../ConferencesList/DistrictConferenceHeader';
import DistrictConferenceItem from '../DistrictConferenceItem';

// import {
//   ACCESS_LEVEL_TARGETS,
//   ConferencesAccessLvl,
//   RenderActionsButton,
// } from '@domain/districts';
// import { mapConferencesFeedbackAccessLevels } from '@domain/districts/mappers';

import { createConferencePage } from '@use-cases/districts/conferences';
import { useNotifications } from '@use-cases/notifications';

import { useDeleteConference } from '@repositories/districts/hooks';

import { localizedNavigate } from '@utils/localized-navigate';

import { GetConferencesResults } from '@domui-use-cases/districts';

import { useTranslation } from '@external/react-i18next';
// import { useFetchAccessLevels } from '@hooks/useFetchAccessLevels';

// import { GetConferencesResults } from '@typings/graphql';

interface Props {
  conferences: GetConferencesResults[];
  title: string;
  isUpcomingConference?: boolean;
  isPresRepConferencesHeader?: boolean;
  districtId?: string;
  isAddConferenceShown?: boolean;
  // renderActionsButton: RenderActionsButton;
  // renderActionsButtonMobile: RenderActionsButton;
  // alignActionsRight?: boolean;
  // conferenceAccessLvls?: ConferencesAccessLvl[] | null;
}

const DistrictConferenceSection: React.FC<Props> = ({
  conferences,
  title,
  isUpcomingConference = false,
  isPresRepConferencesHeader,
  isAddConferenceShown = false,
  // renderActionsButton,
  // renderActionsButtonMobile,
  districtId,
  // alignActionsRight = false,
  // conferenceAccessLvls,
}) => {
  const { t } = useTranslation();
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState<boolean>(
    false
  );
  const [deleteConference] = useDeleteConference();
  const { addError, addSuccess } = useNotifications();

  const [
    selectedConference,
    setSelectedConference,
  ] = useState<GetConferencesResults | null>(null);

  // const [
  //   fetchFeedbackAccessLevels,
  //   { data: feedbackAccessLevels },
  // ] = useFetchAccessLevels();

  const areConferences = conferences.length > 0;

  // useEffect(() => {
  //   // TODO: we will get rid of this logic after implementing hateoas links (MRD-2672)
  //   const presRepDistrictId = head(conferences)?.districtId;

  //   if (!isUpcomingConference && areConferences) {
  //     fetchFeedbackAccessLevels({
  //       variables: {
  //         options: conferences.map(({ id }) => ({
  //           type: ACCESS_LEVEL_TARGETS.DISTRICT_CONFERENCE_FEEDBACK,
  //           id,
  //           targetDistrictId: districtId || presRepDistrictId,
  //         })),
  //       },
  //     });
  //   }
  // }, [isUpcomingConference, areConferences]);

  const showCount =
    (isUpcomingConference && areConferences) || !isUpcomingConference;

  // const selectConference = (conference: GetConferencesResults) => {
  //   setSelectedConference(conference);
  //   setIsDeletionModalOpen(true);
  // };

  const cancelDeleteConference = () => {
    setSelectedConference(null);
    setIsDeletionModalOpen(false);
  };

  const handleDeleteConference = async () => {
    try {
      await deleteConference({
        variables: {
          conferenceId: selectedConference?.id || '',
        },
      });
      addSuccess(
        t('conferences.deletion-successful', 'Conference successfully deleted')
      );
    } catch (error) {
      addError(t('conferences.deletion-error', 'Could not delete conference'));
    } finally {
      cancelDeleteConference();
    }
  };

  return (
    <div
      className={classNames('conference-section', {
        'upcoming-conference': isUpcomingConference,
      })}
    >
      <h4
        className={
          isPresRepConferencesHeader
            ? 'alternate pl-5'
            : 'conference-section-header'
        }
      >
        {title} {showCount && `(${conferences.length})`}
      </h4>

      <ResultsList>
        {showCount && (
          <ResultsListItem
            spacingY={4}
            borderBottomClass=""
            className="table-header"
          >
            <DistrictConferenceHeader />
          </ResultsListItem>
        )}
        {conferences.map(conference => (
          <ResultsListItem
            spacingY={4}
            key={conference.id}
            className="table-header"
          >
            <DistrictConferenceItem
              conference={conference}
              // onDelete={selectConference}
              // renderActionsButton={renderActionsButton}
              // renderActionsButtonMobile={renderActionsButtonMobile}
              // alignActionsRight={alignActionsRight}
              // conferenceAccessLvls={conferenceAccessLvls}
              // conferenceFeedbackAccessLvls={mapConferencesFeedbackAccessLevels(
              //   feedbackAccessLevels
              // )}
            />
          </ResultsListItem>
        ))}
        {isAddConferenceShown && (
          <div className="conference-section-add-button">
            <Button
              secondary
              className="mb-4"
              icon="ui/plus"
              clickHandler={() =>
                districtId &&
                localizedNavigate(createConferencePage(districtId))
              }
            >
              {t('conferences.add-conference', 'Add Conference')}
            </Button>
          </div>
        )}
      </ResultsList>
      <DeleteConferenceModal
        conference={selectedConference}
        isOpen={isDeletionModalOpen}
        onConfirm={handleDeleteConference}
        onCancel={cancelDeleteConference}
      />
    </div>
  );
};

export default DistrictConferenceSection;
