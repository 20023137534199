import { useState } from 'react';

import { DomuiError } from '@domui-domain/type';
import Member from '@domui-utils/workflow/member';

type removeOfficerArgs = {
  leadershipId: string;
  terminationDate: string;
  clubId: string;
};

// Custom hook to handle API calls
export const useRemoveFromAssignedRole = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);

  const removeOfficer = async ({
    leadershipId,
    terminationDate,
    clubId,
  }: removeOfficerArgs) => {
    const requestData = {
      leadershipId,
      terminationDate,
      clubId,
    };
    let removeStatus = false;
    setLoading(true);
    setError(null);
    try {
      const wfService = new Member();
      await wfService.RemoveFromAssignedRole(requestData);
      removeStatus = true;
      setLoading(false);
    } catch (err) {
      setError(err as DomuiError);
      removeStatus = false;
      setLoading(false);
    } finally {
      setLoading(false);
    }
    return removeStatus;
  };

  return { loading, error, removeOfficer };
};
