import { useState } from 'react';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Authorizer from '@domui-utils/workflow/authorizer';
// Custom hook to handle API calls
export const useFetchAccessLevelData = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DynamicData>(null);
  const [error, setError] = useState<DomuiError>(null);

  const fetchAccessLevels = async (dominoId: string, payload: DynamicData) => {
    setLoading(true);
    setError(null); // Clear error before new request

    try {
      const wfService = new Authorizer();
      const targetTermYears = payload?.data[0].arguments.targetTermYear;

      const responses = await Promise.all(
        targetTermYears.map((year: string) => {
          const newPayload = {
            data: [
              {
                target: payload?.data[0].target,
                arguments: {
                  targetTermYear: year,
                },
              },
            ],
          };
          return wfService.getIAMAccessLevels(dominoId, newPayload);
        })
      );

      // Combine the results from all API calls
      const combinedData = responses.reduce((acc, response) => {
        return {
          ...acc,
          data: [...(acc.data || []), ...response.data],
        };
      }, {});

      setData(combinedData);
      return combinedData; // Return the combined data
    } catch (err) {
      setError(err as DomuiError);
      throw err; // Re-throw the error to handle it in the calling function
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, fetchAccessLevels };
};

// import { useState } from 'react';

// import { DomuiError, DynamicData } from '@domui-domain/type';
// import Authorizer from '@domui-utils/workflow/authorizer';

// // Custom hook to handle API calls
// export const useFetchAccessLevelData = () => {
//   const [loading, setLoading] = useState(false);
//   const [data, setData] = useState<DynamicData>(null);

//   const [error, setError] = useState<DomuiError>(null);

//   const fetchAccessLevels = async (dominoId: string, data: DynamicData) => {
//     setLoading(true);
//     setError(null); // Clear error before new request

//     try {
//       const wfService = new Authorizer();
//       const response = await wfService.getIAMAccessLevels(dominoId, data);
//       setData(response);
//     } catch (err) {
//       setError(err as DomuiError);
//       setLoading(false);
//     } finally {
//       setLoading(false);
//     }
//   };

//   return { data, loading, error, fetchAccessLevels };
// };

// import { useEffect } from 'react';

// import { ACCESS_LEVEL_TARGETS } from '@domain/districts';

// import { useAppConfig } from '@hooks/appConfig';
// import { useFetchAccessLevels } from '@hooks/useFetchAccessLevels';

// export const useFetchAccessLevelData = (districtId: string, year?: string) => {
//   const { user } = useAppConfig();

//   const [
//     fetchAccessLevelsQuery,
//     { data: accessLevelData, loading: accessDataLoading },
//   ] = useFetchAccessLevels();

//   useEffect(() => {
//     if (user?.isLoggedIn && districtId && year) {
//       fetchAccessLevelsQuery({
//         variables: {
//           options: [
//             {
//               type: ACCESS_LEVEL_TARGETS.DISTRICT_LEADERSHIP,
//               id: districtId,
//               targetTermYearRange: [year, String(parseInt(year, 10) + 1)],
//             },
//           ],
//         },
//       });
//     }
//   }, [user?.isLoggedIn, districtId, year]);

//   return {
//     accessLevelData,
//     accessDataLoading,
//   };
// };
