import React from 'react';

import { RouteComponentProps } from '@reach/router';

import Loading from '@components/Loading';
import { titleTemplateParams } from '@components/Title/util';

import { DistrictMember, DORoles as DORolesType } from '@domain/districts';

import SearchMembersForm from '@domui-components/Formik/SearchMembersForm';
import { DynamicData } from '@domui-domain/type';
import Filters from '@domui-presenters/web/components/DistrictMembersFilters';
import { MEMBER_FILTERS } from '@domui-presenters/web/components/DistrictMembersFilters/types';
import { getFullName } from '@domui-presenters/web/pages/Clubs/utils';
import { getMemberTypesOptions } from '@domui-presenters/web/pages/Districts/filters/getFiltersOptions';
import ContainerWithLinks from '@domui-presenters/web/pages/Districts/Members/ContainerWithLinks';
import DistrictMemberItem from '@domui-presenters/web/pages/Districts/Members/DistrictMemberItem';
import MembersList from '@domui-presenters/web/pages/Districts/Members/MembersList';
import { useFetchMembersWithFilters } from '@domui-repositories/districts/hooks/useFetchMembersWithFilters';
import {
  buildAGsPageUrl,
  buildOfficersPageUrl,
} from '@domui-use-cases/districts';
import { useClubOptions } from '@domui-use-cases/districts/hooks/useClubOptions';
import { useDistrictMembersFilters } from '@domui-use-cases/districts/hooks/useDistrictMembersFilters';
import { useDistrictOptions } from '@domui-use-cases/districts/hooks/useDistrictOptions';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';

import { MembershipType } from '@typings/graphql';
import { Role } from '@typings/resolvers';

interface Props extends RouteComponentProps {
  districtId: string;
  // isRotaractClub?: boolean;
  // riDistrictId?: number | null;
  pageSize?: number;
}
const PAGE_SIZE = 10;

const getMemberItem = (
  DORoles: Pick<Role, 'id' | 'name' | 'isOfficer' | 'termLength'>[],
  nonGovernorsCurrent: DORolesType,
  nonGovernorsNext: DORolesType,
  districtId: string,
  assignedFuture3yrTermRoleIds: string[],
  isRoleFilterApplied?: boolean
  // rolesSelected?: string[]
) => (member: DistrictMember) => {
  const activeMemberships = member?.activeMemberships;
  const associatedClubsInfo = member?.associatedClubsInfo;

  const filteredActiveMemberships = activeMemberships.filter(
    ({ clubId }: { clubId: string }) =>
      associatedClubsInfo.find(
        (club: { clubId: string }) => club.clubId === clubId
      )
  );
  // const getIsAssigned3yrTermRoleInFutureRY = ({
  //   thisDistrictLeadership,
  // }: DistrictMember) =>
  //   thisDistrictLeadership.length === 1 &&
  //   isRoleAssignedInFutureRY(
  //     assignedFuture3yrTermRoleIds,
  //     thisDistrictLeadership[0].roleId
  //   );

  const haveMemberships = {
    honorary: !!filteredActiveMemberships.find(
      ({ type }: { type: string }) =>
        type === MembershipType.Honorary ||
        type === MembershipType.RotaractHonorary
    ),
    notHonorary: !!filteredActiveMemberships.find(
      ({ type }: { type: string }) =>
        type === MembershipType.Member || type === MembershipType.Rotaractor
    ),
  };

  return (
    <DistrictMemberItem
      // roleSelected={rolesSelected}
      isRoleFilterApplied={isRoleFilterApplied}
      member={member}
      // DORoles={DORoles.filter(role => role.isOfficer)}
      // areRolesToAssignPresent={
      //   nonGovernorsCurrent.length > 0 || nonGovernorsNext.length > 0
      // }
      districtId={districtId}
      //  isAssignedInFutureRY={getIsAssigned3yrTermRoleInFutureRY(member)}
      haveMemberships={haveMemberships}
    />
  );
};
export const processMembers = (members: DynamicData) => {
  return members?.map((member: DynamicData) => ({
    ...member,
    nameWithPrefixSuffix: `${member?.firstName ?? ''} ${member?.lastName ??
      ''}`,
    name: getFullName(
      member as {
        prefix: string;
        firstName: string;
        middleName: string;
        lastName: string;
        suffix: string;
      }
    ),

    email: member?.primaryEmail?.isPrimary ? member?.primaryEmail?.email : '',
    phoneNumber: member?.primaryPhone?.isPrimary
      ? member?.primaryPhone?.phone
      : null,
    associatedClubsInfo:
      member?.membership?.map(
        (club: {
          clubId: String;
          clubName: String;
          clubType: String;
          country: String;
          state: String;
        }) => ({
          __typename: 'Club',
          clubId: club?.clubId ?? '',
          clubName: club?.clubName ?? '',
          clubType: (club?.clubType ?? '').replace(/ /g, '_'),
          physicalLocation: {
            __typename: 'BaseAddress',
            country: club?.country ?? '',
            state: club?.state ?? '',
            city: null,
            internationalProvince: null,
          },
        })
      ) ?? [],
    activeMemberships:
      member?.membership?.map(
        (club: {
          type: String;
          clubName: String;
          clubId: String;
          admissionDate: String;
          terminationDate: String;
        }) => ({
          type: club?.type ?? '',
          clubName: club?.clubName ?? '',
          clubId: club?.clubId ?? '',
          admissionDate: club?.admissionDate ?? '',
          terminationDate: club?.terminationDate ?? '',
        })
      ) ?? [],
    activeLeaderships:
      member?.districtLeadership?.map((leadership: { role: String }) => {
        return {
          type: 'Leadership',
          role: leadership?.role ?? '',
          clubName: member?.membership?.clubName ?? '',
          clubId: member?.membership?.clubId ?? '',
        };
      }) ?? [],

    thisDistrictLeadership: [],
  }));
};

const DistrictMembers: React.FC<Props> = ({
  districtId,
  // location,
  // isRotaractClub = false,
  // riDistrictId,
  pageSize = PAGE_SIZE,
}) => {
  // const [context] = useContext(DistrictContext);

  // const {
  //   committeeManager: { isManager },
  //   selectedInfo: { term },
  //   termsToAssign,
  // } = context;
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);

  const memberTypesOptions = getMemberTypesOptions(t);

  // const canAddMembers = false; // isEditLevel(operationsAccess.memberships);

  const { options: roleOptions } = useClubOptions(districtId);
  const { options: districtOptions } = useDistrictOptions(districtId);

  const multiSelectOptions = {
    clubs: roleOptions,
    memberTypes: memberTypesOptions,
    districtRoles: districtOptions,
  };

  // const {
  //   data: organizationTypeRoleMappingData,
  //   loading: isOrgTypeRelationLoading,
  //   roleMappingerror,
  //   fetchOrganizationTypeRoleMapping,
  // } = useFetchOrganizationTypeRoleMappingByOrganizationId();

  // useEffect(() => {
  //   if (districtId) {
  //     fetchOrganizationTypeRoleMapping(districtId);
  //   }
  // }, [districtId]);
  //  const isManagerTwoYears = isManager.currentTerm && isManager.nextTerm;
  //  const isElect = !isManager.currentTerm && isManager.nextTerm;
  const {
    selectedValues,
    filterValues,
    currentPage,
    setCurrentPage,
    handleSearch,
    handleApplyFilters,
    handleResetFilters,
  } = useDistrictMembersFilters(districtId);
  // const isRoleFilterApplied = Boolean(
  //   filterValues?.filters?.districtRoles?.length > 0
  // );
  // const rolesSelected = filterValues?.filters?.districtRoles?.map(
  //   ({ value }) => value
  // );

  const { districtMembersSearchData, loading } = useFetchMembersWithFilters(
    currentPage,
    PAGE_SIZE,
    filterValues,
    selectedValues
  );
  if (loading) {
    return <Loading />;
  }

  if (
    !districtMembersSearchData ||
    !districtMembersSearchData?.districtMembersDetails
  ) {
    return null;
  }
  const handlePagination = (
    event: React.SyntheticEvent,
    pageNumber: number
  ) => {
    event.preventDefault();
    setCurrentPage(pageNumber);
  };

  const totalCount = districtMembersSearchData?.totalCount || 0;
  const total = `(${totalCount})`;

  // added for running application for now will be removed later
  const DORoles: Pick<Role, 'id' | 'name' | 'isOfficer' | 'termLength'>[] = [];
  const nonGovernorsCurrent: DORolesType = [];
  const nonGovernorsNext: DORolesType = [];
  const assignedFuture3yrTermRoleIds: string[] = [];

  const members = districtMembersSearchData.districtMembersDetails;

  const processedMembers = processMembers(members);

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-members',
          '{{prefix}} District members {{suffix}}',
          { prefix, suffix }
        )}
      />
      <ContainerWithLinks
        isDistrictOfficer
        isAssistantGovernor
        assistantGovernorsUrl={buildAGsPageUrl(districtId)}
        officersUrl={buildOfficersPageUrl(districtId)}
      />
      <SearchMembersForm
        initialValues={filterValues}
        loading={loading}
        submitHandler={handleSearch}
        title={{
          classes: 'mb-5 tablet:mb-0 mt-2 tablet:normal-case capitalize',
          value: t(
            'district-management.search-members-title',
            'Search district members'
          ),
        }}
        isLabelsHidden
      />
      <MembersList
        pageSize={pageSize}
        currentPage={currentPage}
        pageHandler={handlePagination}
        totalCount={districtMembersSearchData.totalCount}
        members={processedMembers || []}
        tableTitle={
          <h2 className="font-bold uppercase tracking-wide text-sm mb-0">
            {t('district-management.district-members', 'District members')}{' '}
            {total}
          </h2>
        }
        filters={
          <Filters
            {...{
              multiSelectOptions: { ...multiSelectOptions },
              initialFilterValues: { ...selectedValues },
              applyFilter: handleApplyFilters,
              resetFilter: handleResetFilters,
              filtersListToShow: [
                MEMBER_FILTERS.CLUB_NAMES,
                MEMBER_FILTERS.MEMBER_TYPES,
                MEMBER_FILTERS.DISTRICT_ROLES,
              ],
            }}
          />
        }
        memberItem={getMemberItem(
          DORoles,
          nonGovernorsCurrent,
          nonGovernorsNext,
          districtId,
          assignedFuture3yrTermRoleIds
          //  isRoleFilterApplied,
          //  rolesSelected
        )}
        showFiltersInContextMenu
        contextMenuTitle={t(
          'district.filters.members',
          'Filter District members'
        )}
        areFiltersEmpty={!total}
      />
    </>
  );
};

export default DistrictMembers;
