import { useState } from 'react';

import { useNotifications } from '@use-cases/notifications';

import { localizedNavigate } from '@utils/localized-navigate';

import { DomuiError, DynamicData } from '@domui-domain/type';
import Member from '@domui-utils/workflow/member';

import { useTranslation } from '@external/react-i18next';

type removeOfficerArgs = {
  individualId: string;
  clubId: string;
  admissionDate: string;
  memberType: string;
  sponsorIds: DynamicData;
};

// Custom hook to handle API calls
export const useEditClubMembership = () => {
  const { t } = useTranslation();
  const { addSuccess } = useNotifications();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<DomuiError>(undefined);

  const editClubMembership = async ({
    individualId,
    clubId,
    admissionDate,
    memberType,
    sponsorIds,
  }: removeOfficerArgs) => {
    const requestData = {
      individualId,
      clubId,
      admissionDate,
      memberType,
      sponsorIds,
    };
    setLoading(true);
    setError(null);
    try {
      const wfService = new Member();
      await wfService.EditClubMembership(requestData);
      addSuccess(
        t('edit-membership.submit-edit-membership-form.success', 'Success.'),
        { id: 'form.success' }
      );
      localizedNavigate(`/domui/club/${clubId}/roster`);
      setLoading(false);
    } catch (error) {
      setError(error as DomuiError);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return { loading, error, editClubMembership };
};
