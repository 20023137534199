import { DynamicData } from '@backend/modules/domui/types';

export default (
  individual?: DynamicData,
  individualData?: DynamicData,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  countriesRegions?: any
) => {
  const country = countriesRegions?.filter(
    (country: { id: string }) =>
      country.id === individual?.primaryAddress?.countryId
  );
  return {
    //  id,
    firstName: individual?.firstName,
    lastName: individual?.lastName,
    middleName: individual?.middleName,
    prefix: individual?.prefix,
    suffix: individual?.suffix,
    gender: individual?.gender,
    localizedName: individual?.localizedName,
    dateOfBirth: individual?.dateOfBirth || '',
    youthFlag: individualData?.youthFlag || false,
    yearOfBirth: individual?.yearOfBirth,
    languages: [
      {
        //   id: language[0]?.id,
        languageId: individual?.primaryLanguage,
        isPrimary: true,
        action: 'Add',
      },
    ],
    emails: [
      {
        // id: email[0]?.id,
        type: individual?.primaryEmail?.type.toLowerCase(),
        address: individual?.primaryEmail?.address,
        isPrimary: true,
        action: 'Add',
      },
    ],
    addresses: [
      {
        //  id: address[0]?.id,
        type: individual?.primaryAddress?.type,
        lineOne: individual?.primaryAddress?.lineOne,
        lineTwo: individual?.primaryAddress?.lineTwo,
        lineThree: individual?.primaryAddress?.lineThree,
        countryId: individual?.primaryAddress?.countryId,
        countryName: country?.[0]?.countryName,
        city: individual?.primaryAddress?.city,
        stateId: individual?.primaryAddress?.stateId,

        // internationalProvince: 'string',
        postalCode: individual?.primaryAddress?.postalCode,
        isPrimary: true,
        action: 'Add',
      },
    ],
    phones: [
      {
        // id: phone[0]?.id,
        type: individual?.primaryPhone?.type,
        number: individual?.primaryPhone?.number,
        countryId: individual?.primaryPhone?.countryId,
        // extension: 'string',
        isPrimary: true,
        //   isFax: true,
        //   nfKey: '3fa85f64-5717-4562-b3fc-2c963f66afa6',
        action: 'Add',
      },
    ],
    professions: [],
    expertises: [],
  };
};
