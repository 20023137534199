import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import moment from 'moment';

import Wizard from '@components/Formik/Wizard';
import Loading from '@components/Loading';
import LeaveFormConfirmationModal from '@components/Modals/LeaveFormConfirmationModal';
import {
  confirmOfficerValidationSchema,
  selectRoleValidationSchema,
} from '@presenters/web/pages/Clubs/ManageClubOfficers/validationSchema';

import {
  getTermByYear,
  useModal,
  useStopBrowserNavigate,
} from '@use-cases/districts';
import { useErrorHandling, useNotifications } from '@use-cases/notifications';

import { getRotaryYear } from '@utils/datetime';
import { getClubRoleTValue } from '@utils/getClubRoleTValue';
import { localizedNavigate } from '@utils/localized-navigate';
import { getChannel } from '@utils/sendMessage';

import {
  Club,
  ConfirmOfficerValues,
  Individual,
  SelectRoleValues,
} from '@domui-domain/clubs/types';
import { useFetchIndividualForAssignClubRole } from '@domui-hooks/useFetchIndividualForAssignClubRole';
import ConfirmOfficer from '@domui-presenters/web/pages/Clubs/ManageClubOfficers/ConfirmOfficer/ConfirmOfficer';
import SelectRole from '@domui-presenters/web/pages/Clubs/ManageClubOfficers/SelectRole/SelectRole';
import { getClubMembersPath } from '@domui-use-cases/clubs';
import { useAssignRoleToMember } from 'src/domui/hooks/useAssignClubOfficerRole';
import { getFullName } from '@domui-presenters/web/pages/Clubs/utils';

import { useTranslation } from '@external/react-i18next';

// import { EmailType, MembershipType } from '@typings/operations';
// import { MembershipType } from '@typings/operations';

enum MembershipType {
  Honorary = 'Honorary',
  Member = 'Member',
  RotaractHonorary = 'Rotaract_Honorary',
  Rotaractor = 'Rotaractor',
}

type primaryMailType = {
  id: string;
  nfKey: string;
  type: string;
  address: string;
  isPrimary: boolean;
};

interface AssignRoleToMemberProps extends RouteComponentProps {
  clubId: string;
  individualId: string;
}

type AssignRoleToMemberValues = Partial<
  SelectRoleValues & ConfirmOfficerValues
>;

const AssignRoleToMember: React.FC<AssignRoleToMemberProps> = ({
  clubId,
  individualId,
}) => {
  const { t } = useTranslation();
  const { addError, addSuccess } = useNotifications();
  const {
    data,
    loading,
    error,
    FetchIndividualForAssignClubRole,
  } = useFetchIndividualForAssignClubRole();
  const [isFieldTouched, setIsFieldTouched] = useState<boolean>(false);
  const [isBackClubMembersPage, setIsBackClubMembersPage] = useState<boolean>(
    false
  );
  const DATE_FORMAT = 'YYYY-MM-DD';

  const isFirstStep = (step: number) => step === 0;

  const { isShowing, show } = useModal(window.stopBrowserNavigate);
  const modalBackHandler = () => {
    show(true);
  };
  const { globalHide, setGlobal } = useStopBrowserNavigate({
    showModal: show,
    isNextStepVisited: true,
    onNavigate: modalBackHandler,
  });

  useErrorHandling(error?.message, !!error, 'assign-role.error');

  const {
    error: errorCreateClubLeadership,
    assignRoleToMember,
  } = useAssignRoleToMember();

  const stepBack = (step: number, setStep: (step: number) => void) => {
    isFirstStep(step)
      ? localizedNavigate(getClubMembersPath(clubId))
      : setStep(step - 1);
  };

  const leavePage = (step: number, setStep: (step: number) => void) => {
    globalHide();

    if (isBackClubMembersPage) {
      localizedNavigate(getClubMembersPath(clubId));
    }
    stepBack(step, setStep);
  };

  useEffect(() => {
    if (clubId && individualId) {
      FetchIndividualForAssignClubRole(individualId, clubId);
    }
  }, [clubId, individualId]);

  const handleBackArrowClick = (
    step: number,
    setStep: (step: number) => void
  ) => {
    if (isFieldTouched) {
      show(true);
    } else {
      stepBack(step, setStep);
    }
  };
  const handleFormFieldsTouched = (isTouched: boolean) => {
    setTimeout(() => {
      setIsFieldTouched(isTouched);
    }, 0);
  };

  const handleCancelBtnClick = (e?: React.MouseEvent) => {
    e?.preventDefault();
    if (isFieldTouched) {
      show(true);
      setIsBackClubMembersPage(true);
    } else {
      localizedNavigate(getClubMembersPath(clubId));
    }
  };

  if (!data || loading || error) {
    return <Loading />;
  }

  SelectRole.schema = selectRoleValidationSchema(t);
  ConfirmOfficer.schema = confirmOfficerValidationSchema(t);

  const type: MembershipType = MembershipType.Member;

  const primaryEmail = data?.emails?.find((item: primaryMailType) => {
    if (item.isPrimary === true) {
      return item.address;
    }
    return '';
  });

  const individual: Individual = {
    id: data.id,
    name: getFullName(
      data as {
        prefix: string;
        firstName: string;
        middleName: string;
        lastName: string;
        suffix: string;
      }
    ),
    email: null, // primaryEmail,
    photoUri: null,
    membershipAdmissionDate: null,
    membershipTerminationDate: null,
    onlineId: null,
  };

  const club: Club = {
    id: clubId,
    name: 'Club Name',
    clubType: '',
    riClubId: '',
  };

  // const clubAffiliations =
  //   data.individual.clubAffiliations?.[0]?.affiliations || [];

  // const membershipAffiliation = clubAffiliations.find(
  //   affiliation => affiliation.__typename === 'MembershipAffiliation'
  // );

  // if (membershipAffiliation?.__typename === 'MembershipAffiliation') {
  //   type = membershipAffiliation.membershipType;
  //   individual.membershipAdmissionDate = membershipAffiliation.admissionDate;
  //   individual.membershipTerminationDate =
  //     membershipAffiliation.terminationDate;
  // }

  const handleSubmit = async (values: AssignRoleToMemberValues) => {
    const { role, individual, slot } = values;
    const startDate = moment(slot?.start).format(DATE_FORMAT);
    const endDate = moment(slot?.end).format(DATE_FORMAT);

    await assignRoleToMember({
      individualPk: individualId,
      rolePk: role?.id,
      startDate,
      endDate,
      organizationPk: clubId,
    });

    if (errorCreateClubLeadership) {
      addError(errorCreateClubLeadership?.message || '');
    } else {
      const individualName = individual?.name;

      addSuccess(
        t(
          'club-add-officer.assign-role.success-message',
          'Success! {{name}} has been assigned the role of {{role}}.',
          {
            name: individualName,
            role: getClubRoleTValue(t, role?.name || ''),
          }
        ),
        { id: 'form.success' }
      );
      getChannel('manage-club-officers').postMessage(
        t(
          'club-officer-tab-update.message',
          'Please refresh this browser tab to see updated information.'
        )
      );
      setGlobal(false);
      localizedNavigate(getClubMembersPath(clubId));
    }
  };

  const checkPopup = (step: number, setStep: (step: number) => void) => {
    if (isShowing && (!isFieldTouched || isFirstStep(step))) {
      leavePage(step, setStep);
    }
  };

  const getConfirmationModal = (
    step: number,
    setStep: (step: number) => void
  ) => {
    return (
      <LeaveFormConfirmationModal
        isOpen={isFieldTouched && isShowing}
        closeModal={() => {
          globalHide();
          setIsBackClubMembersPage(false);
        }}
        onConfirm={() => {
          handleFormFieldsTouched(false);
          leavePage(step, setStep);
          window.scrollTo(0, 0);
        }}
      />
    );
  };

  return (
    <Wizard
      pages={[SelectRole, ConfirmOfficer]}
      initialValues={{
        club,
        individual,
        selectedTerm: getTermByYear(Number(getRotaryYear())),
        email: primaryEmail?.address,
        type,
        customSlot: null,
      }}
      onSubmit={handleSubmit}
      handleBackArrowClick={handleBackArrowClick}
      handleFormFieldsTouched={handleFormFieldsTouched}
      handleCancelBtnClick={handleCancelBtnClick}
      confirmationModal={getConfirmationModal}
      checkPopup={checkPopup}
    />
  );
};

export default AssignRoleToMember;
