import React from 'react';

import Button from '@rotaryintl/harris-button';

import { OneColumn } from '@components/Layouts/OneColumn';
import AccessDenied from '@components/Pages/AccessDenied';
import NotFound from '@components/Routing/NotFound';

import { FEATURE_INVOICE, isEnabled } from '@utils/features';

import { useTranslation } from '@external/react-i18next';

interface Props {
  isManager: boolean;
}

const ViewClubBalanceReport: React.FC<Props> = ({ isManager }) => {
  const { t } = useTranslation();
  const isInvoiceEnabled = isEnabled(FEATURE_INVOICE);

  if (!isInvoiceEnabled) {
    return <NotFound />;
  }

  if (!isManager) {
    return (
      <OneColumn className="mb-20">
        <AccessDenied />
      </OneColumn>
    );
  }

  return (
    <OneColumn className="mb-20">
      <Button
        clickHandler={() => window.history.back()}
        icon="fas-angle-left"
        size="md"
        variant="text"
        className="mt-6"
      >
        {t('back-link-text', 'Back')}
      </Button>
      <h1 className="font-semibold text-xl-38 mobile-m:text-xl-48 tablet:text-xl-58 tablet:p-0 m-0 my-6 tablet:mb-8 tablet:leading-xl-58">
        {t('daily-club-balance-report.title', 'Daily Club Balance Report')}
      </h1>
        <div id="DailyClubBalanceDownloadReport">
          <span>
            {t(
              'daily-club-balance-report.download-update-text',
              'We are downloading daily club balance report for you and opening it in new tab.'
            )}
          </span>
        </div>
    </OneColumn>
  );
};

export default ViewClubBalanceReport;
