import React, { useEffect, useReducer } from 'react';

import CoveoContentSearch from '@rotaryintl/app-coveo-content-search';

import { OneColumn } from '@components/Layouts/OneColumn';
import { Submenu } from '@components/Submenu';
import Title from '@components/Title';

import { SiteSearchFormValues } from '@domain/search';

import {
  getSiteParamsFromURL,
  validateSiteParamsFromURL,
} from '@use-cases/districts';

import { useTranslation } from '@external/react-i18next';
import { useMenu } from '@hooks/menu';

export type Lang = 'en' | 'it' | 'de' | 'es' | 'fr' | 'pt' | 'ja' | 'ko';

const SiteSearch: React.FC = location => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const {
    data: submenuData,
    error: submenuError,
    loading: submenuLoading,
  } = useMenu('menu-my-rotary-search-navigation', i18n.language);

  // The difference between the active filters and the above defined filters is
  // that the active filters are used for updating the actual results list. The
  // filters store the current state of the filter form, so every time the user
  // changes something in the form, the filters are updated (which can trigger
  // or not the update of the active filters).
  const defaultFilters = {
    keywords: '',
    contentType: '',
    documentMimeType: '',
    langcode: currentLanguage,
    topic: '',
  };

  const [filters, setFilters] = useReducer(
    (state: SiteSearchFormValues, action: SiteSearchFormValues) => ({
      ...state,
      ...action,
    }),
    defaultFilters
  );

  if (filters.contentType !== 'document') {
    filters.documentMimeType = '';
  }

  useEffect(() => {
    const filtersFromURL = getSiteParamsFromURL();
    const validatedFilters = validateSiteParamsFromURL(filtersFromURL);
    const newFilter = { ...filters, ...validatedFilters };
    setFilters(newFilter);
  }, []);

  return (
    <OneColumn>
      <Title>{t('search.title', 'Search')}</Title>
      {!submenuLoading && !submenuError && submenuData.length && (
        <Submenu items={submenuData} location={location} />
      )}
      <div className="mt-6 mb-20">
        <CoveoContentSearch
          accessToken="xx14083c03-944c-4838-9ed9-0f168b79bfd1"
          organizationId="rotarytrialneloodqq"
          searchHub="MyRotary-and-Rotary.org"
          queryPipeline="MyRotary-and-Rotary.org"
          language={currentLanguage as Lang}
          query=""
          showLanguageFacet={false}
        />
      </div>
    </OneColumn>
  );
};

export default SiteSearch;
