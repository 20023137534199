import React, { useEffect, useState } from 'react';

import { RouteComponentProps } from '@reach/router';
import { Form, Formik } from 'formik';
import { size } from 'lodash';
import moment from 'moment';

import { Button } from '@components/Button';
import Calendar from '@components/Formik/Calendar';
import Loading from '@components/Loading';
import Note from '@components/Note';
import PageSteps from '@components/PageSteps';

import { getCalendarValues } from './utils';
import { changeMembershipValidationSchema } from './validationSchema';
import IndividualInfo from '../IndividualInfo';

import { CLUB_MEMBERSHIP_MANAGEMENT_STEPS } from '@domain/clubs/constants';

import { useErrorHandling } from '@use-cases/notifications';

import { SponsorSelect } from '@domui-components/Forms/Select/SponsorSelect';
import { useEditClubMembership } from '@domui-hooks/useEditClubMembership';
import { useMembershipActions } from '@domui-hooks/useMembershipActions';
import {
  getClubMembersPath,
  getEditMembershipPath,
  isMemberOrRotaractor,
} from '@domui-use-cases/clubs';
import { getFullName } from '@domui-presenters/web/pages/Clubs/utils';

import { useTranslation } from '@external/react-i18next';

import { MembershipType } from '@typings/operations';

export type ChangeMembershipProps = {
  clubId: string;
  individualId: string;
} & RouteComponentProps;

type FormValues = {
  effectiveDate: Date;
};

const CALENDAR_DATE_FORMAT = 'dd MMM yyyy';

const ChangeMembership: React.FC<ChangeMembershipProps> = ({
  clubId,
  individualId,
}) => {
  const { t } = useTranslation();

  const [sponsorId, setSponsorId] = useState<Array<string>>([]);

  const { editClubMembership } = useEditClubMembership();

  const {
    data,
    loading,
    error,
    fetchMembershipActions,
  } = useMembershipActions();

  useEffect(() => {
    if (clubId && individualId) {
      const orgId = clubId;
      fetchMembershipActions(orgId, individualId);
    }
  }, [clubId, individualId]);

  useErrorHandling(error?.message, !!error);

  if (loading || !data) {
    return <Loading />;
  }

  const { club } = data;

  const {
    photoUri,
    individualPk,
    prefix,
    firstName,
    middleName,
    lastName,
    suffix,
    admissionDate,
    memberType: membershipType,
  } = data.member;

  const individual = {
    prefix,
    firstName,
    middleName,
    lastName,
    suffix,
  };

  const isMemberOrRotaractorMembershipType = isMemberOrRotaractor(
    membershipType
  );

  const {
    initialEffectiveDate,
    maxEditableDate,
    minEditableDate,
    isDisabled,
    minCalendarDate,
  } = getCalendarValues(admissionDate);

  const getUpdatedMembershipType = (membershipType: MembershipType) => {
    const updatedMembershipType: Record<MembershipType, MembershipType> = {
      [MembershipType.Member]: MembershipType.Honorary,
      [MembershipType.Honorary]: MembershipType.Member,
      [MembershipType.Rotaractor]: MembershipType.RotaractHonorary,
      [MembershipType.RotaractHonorary]: MembershipType.Rotaractor,
    };
    return updatedMembershipType[membershipType];
  };

  const handleOnSubmit = async (values: FormValues) => {
    let memberType: string = getUpdatedMembershipType(membershipType);
    if (memberType === 'Honorary') {
      memberType = 'Honorary Member';
    }
    await editClubMembership({
      individualId,
      clubId,
      admissionDate: moment(values.effectiveDate).format('YYYY-MM-DD'),
      memberType,
      sponsorIds: sponsorId,
    });
  };

  return (
    <Formik
      initialValues={{
        effectiveDate: initialEffectiveDate,
      }}
      onSubmit={handleOnSubmit}
      validationSchema={changeMembershipValidationSchema(
        t,
        minEditableDate,
        maxEditableDate
      )}
    >
      {({ isValid, errors, isSubmitting }) => {
        const { effectiveDate: validationError } = errors;

        return (
          <Form>
            <PageSteps
              className="p-0"
              backBtnClassName="inline-flex items-baseline font-bold text-bright-blue-600 text-xs leading-xs-heading"
              to={getEditMembershipPath(clubId, individualId)}
              step={CLUB_MEMBERSHIP_MANAGEMENT_STEPS.CHANGE_MEMBERSHIP}
              total={size(CLUB_MEMBERSHIP_MANAGEMENT_STEPS)}
              isArrowLarge
              navClassName="mt-6 desktop:mt-8 text-small font-bold"
            />

            <h2 className="mt-4 mb-7 font-light">
              {isMemberOrRotaractorMembershipType
                ? t(
                    'change-membership.form.change-to-honorary-title',
                    'Change to Honorary Member'
                  )
                : t(
                    'change-membership.form.change-to-active-title',
                    'Change to Active Member'
                  )}
            </h2>
            <IndividualInfo
              photoUri={photoUri}
              individualId={individualPk}
              individualName={getFullName(individual)}
              club={club}
            />
            {isMemberOrRotaractorMembershipType && (
              <Note className="mb-7 desktop:mb-8">
                <p className="text-md">
                  {t(
                    'change-membership.form.member-can-no-longer-hold-office',
                    'This member can no longer hold office'
                  )}
                </p>
                <p className="text-md">
                  {t(
                    'change-membership.form.changing-member-to-honorary-text',
                    "Changing Member Type to Honorary will end this member's eligibility to hold office and will remove current position(s) and remove any future position(s). *RI Bylaws, Article 4.050.2, Club Constitution Article 7, Section 6B."
                  )}
                </p>
              </Note>
            )}

            <div className="max-w-lg" data-testid="change-membership-form">
              <Calendar
                name="effectiveDate"
                label={t(
                  'change-membership.form.effective-date-label',
                  'Effective Date'
                )}
                required
                disabled={isDisabled}
                minDate={minCalendarDate}
                maxDate={maxEditableDate}
                dateFormat={CALENDAR_DATE_FORMAT}
              />
              {!isValid && (
                <Note>
                  <p>{validationError}</p>
                </Note>
              )}
              {membershipType === MembershipType.Honorary && (
                <SponsorSelect
                  selectId="sponsorId"
                  selectName="sponsorId"
                  selectLabel={t(
                    'change-membership.form.sponsored-by-label',
                    'Sponsored by'
                  )}
                  description={t(
                    'change-membership.form.sponsors-maximum',
                    '2 sponsors maximum'
                  )}
                  handleChange={(individualId: string[]) =>
                    setSponsorId(individualId)
                  }
                  clubId={clubId}
                  currentIndividualId={individualId}
                />
              )}
            </div>
            <div className="mt-15 flex flex-col">
              <Button
                className="max-w-lg w-full"
                type="submit"
                disabled={!isValid || isSubmitting}
              >
                {t('change-membership.form.submit-label', 'Save Changes')}
              </Button>
              <Button
                to={getClubMembersPath(clubId)}
                text
                className="max-w-lg w-full mt-6"
              >
                {t('change-membership.form.cancel-button', 'Cancel')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangeMembership;
