import React, { useEffect } from 'react';

import Loading from '@components/Loading';
import NotFound from '@components/Routing/NotFound';
import { titleTemplateParams } from '@components/Title/util';

// import ActionButtons from '../components/ActionButtons';
// import ActionButtonsMobile from '../components/ActionButtonsMobile';
import DistrictConferenceSection from '../components/DistrictConferenceSection';

// import {
//   ACCESS_LEVEL_TARGETS,
//   mapConferencesAccessLevel,
// } from '@domain/districts';

// import { useFetchConferences } from '@repositories/districts/hooks';

// import { getRotaryYear } from '@utils/datetime';
import { RecordList } from '@domui-domain/type';
import { useFetchAllConferencesByDistrictPrimary } from '@domui-hooks/conference/useAllConferencesByDistrictPrimary';
// import { GetConferencesResults } from '@domui-use-cases/districts';
import {
  // getIsManagerByYear,
  getSplitConferences,
  // isCreateConferenceBtn,
} from '@domui-use-cases/districts/conferences/index';

import { Helmet } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
// import { useFetchAccessLevels } from '@hooks/useFetchAccessLevels';

// import { GetAccessLevelsQuery } from '@typings/operations';
interface Props {
  districtId: string;
  hasPermissionToConference: boolean;
  // accessLevelData: GetAccessLevelsQuery;
}

const DistrictConferences: React.FC<Props> = ({
  districtId,
  hasPermissionToConference,
  // accessLevelData: districtAccessLevelByYears,
}) => {
  const { t } = useTranslation();
  const { prefix, suffix } = titleTemplateParams(t);
  const {
    data: conferenceData,
    loading: conferenceLoading,
    fetchAllConferencesByDistrictPrimary,
  } = useFetchAllConferencesByDistrictPrimary();

  useEffect(() => {
    if (districtId) {
      fetchAllConferencesByDistrictPrimary(districtId);
    }
  }, [districtId]);

  // const [
  //   fetchConferences,
  //   { data: conferenceData, loading: conferenceLoading },
  // ] = useFetchConferences();

  // const [
  //   fetchAccessLevelsForConferences,
  //   { data: accessLevelDataForConferences, loading: accessLvlLoading },
  // ] = useFetchAccessLevels();

  // const currentRY = getRotaryYear();
  // const RY = Number(currentRY);
  // const nextRY = RY + 1;

  // const isFutureDG = getIsManagerByYear(districtAccessLevelByYears, nextRY);

  // useEffect(() => {
  //   fetchAccessLevelsForConferences({
  //     variables: {
  //       options: [
  //         {
  //           type: ACCESS_LEVEL_TARGETS.DISTRICT_CONFERENCES,
  //           id: districtId,
  //         },
  //       ],
  //     },
  //   });
  // }, []);

  // useEffect(() => {
  //   if (hasPermissionToConference) {
  //     let minStartDate = `${RY - 4}-07-01`;

  //     if (isFutureDG) {
  //       minStartDate = `${RY - 3}-07-01`;
  //     }

  //     fetchConferences({
  //       variables: {
  //         districtIds: [districtId],
  //         minStartDate,
  //         maxStartDate: `${nextRY}-07-01`,
  //       },
  //     });
  //   }
  // }, [hasPermissionToConference]);

  if (!hasPermissionToConference) {
    return <NotFound default />;
  }

  if (conferenceLoading || !conferenceData) {
    return <Loading />;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const conferences: RecordList = conferenceData || [];

  const { pastConferences, upcomingConferences } = getSplitConferences(
    conferences
  );

  // const conferenceAccessLvls = mapConferencesAccessLevel(
  //   accessLevelDataForConferences
  // );

  return (
    <>
      <Helmet
        titleTemplate={t(
          'metadata.title.district-conferences',
          '{{prefix}} District conferences {{suffix}}',
          { prefix, suffix }
        )}
      />
      <div className="conference-page">
        <DistrictConferenceSection
          districtId={districtId}
          conferences={upcomingConferences}
          title={t('conferences.upcoming-title', 'Upcoming Conferences')}
          isUpcomingConference
          // conferenceAccessLvls={conferenceAccessLvls}
          // Create Conference button is displayed for current or future DG if there is no conferences in their managment year
          // isAddConferenceShown={isCreateConferenceBtn(
          //   conferences
          //   // districtAccessLevelByYears
          // )}
          // renderActionsButton={ActionButtons}
          // renderActionsButtonMobile={ActionButtonsMobile}
        />
        <DistrictConferenceSection
          districtId={districtId}
          // conferenceAccessLvls={conferenceAccessLvls}
          conferences={pastConferences}
          title={t('conferences.past-title', 'Past Conferences')}
          // renderActionsButton={ActionButtons}
          // renderActionsButtonMobile={ActionButtonsMobile}
        />
      </div>
    </>
  );
};

export default DistrictConferences;
